<template>
   <v-dialog :value="!!value" v-on:input="close" max-width="680" >
      <v-card>
         <v-card-title>
            Detail Transaksi
            <v-spacer/>
            <v-icon color="red" @click="close">mdi-close</v-icon>
         </v-card-title>
         <v-divider class="mx-4 mb-4"></v-divider>
         <v-card-text>
            <div class="list-line">
               <label class="grey--text">ID</label>
               <div>{{fm.transaction_id}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">No Transaksi</label>
               <div>{{fm.transaction_number}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Waktu</label>
               <div v-dater="fm.transaction_created_datetime"/>
            </div>
            <div class="list-line">
               <label class="grey--text">Kuantiti</label>
               <div>{{fm.transaction_quantity}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Ongkos Kirim</label>
               <div v-idr="fm.transaction_shipping_amount"/>
            </div>
            <div class="list-line">
               <label class="grey--text">Asuransi Pengiriman</label>
               <div v-idr="fm.transaction_insurance_amount"/>
            </div>
            <div class="list-line">
               <label class="grey--text">Total Nominal</label>
               <div v-idr="fm.transaction_total_amount"/>
            </div>
            <div class="list-line">
               <label class="grey--text">Nomminal Bersih</label>
               <div v-idr="fm.transaction_nett_amount"/>
            </div>
            <div class="list-line">
               <label class="grey--text">Status</label>
               <div  class="text-right">
                  {{fm.transaction_status_description}}
               </div>
            </div>
            <div class="list-line">
               <label class="grey--text">Kurir</label>
               <div>{{fm.shipping && fm.shipping.shipping_name}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Marketplace</label>
               <div>{{fm.store && fm.store.store_name}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Pembayaran</label>
               <div>{{fm.payment && fm.payment.payment_name}}</div>
            </div>
            <div class="title mt-3 mb1">Detail</div>
            <v-data-table
               :headers="header"
               :items="fm.transaction_detail"
               class="elevation-1"
            />
         </v-card-text>
         <!-- <v-card-actions class="justify-center">
            <v-btn v-if="edit" width="220" color="primary" outlined rounded @click="save">save</v-btn>
            <dir v-else>
               <v-btn class="mr-2" color="indigo" outlined @click="edit=true;title='Edit'"><v-icon>mdi-pencil</v-icon>edit</v-btn>
            </dir>
         </v-card-actions> -->
      </v-card>
   </v-dialog>
</template>
<script>
import api from '../api';
import CFG from '../config';
export default {
   props: ['value'],
   data: () => ({
      title: 'Detail',
      fm: {},
      header: [
         {text:'Produk', value: 'product.product_name'},
         {text:'Detail ID', value: 'transaction_detail_id'},
         {text:'Quantity', value: 'transaction_detail_quantity'},
         {text:'Nominal Produk', value: 'transaction_detail_product_amount'},
         {text:'Diskon', value: 'transaction_detail_discount_amount'},
         {text:'Subsidi', value: 'transaction_detail_subsidy_amount'},
      ],
      edit: false,
      load_data: false,
      load_save: false,
      categorys: [],
      load_category: false,
      img_url: CFG.img_url,
   }),
   watch: {
      value(val) {
         if(val) {
            this.title = 'Detail';
            this.edit = false;
            api.get(`transaction/${val}`).then(rsl => {
               if(rsl.data) this.fm = rsl.data;
            });
         }
      }
   },
   mounted() {
   },
   methods: {
      close() {
         this.$emit('input');
         this.fm = {};
      },
   }
}
</script>