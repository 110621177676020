<template>
   <v-container>
      <crud
         api-path="transaction"
         title="Transaksi"
         :field="field"
         key-id="transaction_id"
         ref="crud"
      >
         <template v-slot:row_act="dat">
            <v-btn color="indigo" icon @click="view_id=dat.transaction_id" title="detail"><v-icon>mdi-eye</v-icon></v-btn>
         </template>
         <template v-slot:add_btn>&nbsp;</template>
      </crud>
      <transaction-detail v-model="view_id"/>
   </v-container>
</template>

<script>
import crud from '@/components/crud';
import api from '../api';
import TransactionDetail from '../components/TransactionDetail.vue';

export default {
   components: { crud, TransactionDetail, },
   data: () => ({
      field: [
         { value: 'transaction_id', label: '#', tbl: true },
         { value: 'transaction_number', label: 'No', tbl: true, searchKey: true },
         { value: 'transaction_created_datetime', label: 'Waktu', tbl: true, time: true },
         { value: 'transaction_quantity', label: 'Quantity', tbl: true },
         { value: 'transaction_total_amount', label: 'Nominal', tbl: true, idr: true },
         { value: 'store_id', label: 'Marketplace', tbl: true, master: ['store', 'ms_store_id','store_identifier','list/ms_store']},
         { value: 'payment_id', label: 'Pembayaran', tbl: true, filter: true, master: ['payment', 'payment_id','payment_name']},
         { value: 'shipping_id', label: 'Kurir', tbl: true, filter: true, master: ['shipping', 'shipping_id','shipping_name']},
         { value: 'act', label: 'Aksi', tbl: true, dis_sort: true }
      ],
      view_id: null,
   }),
   methods: {
      activate(dat) {
         const val = dat.transaction_active_status===1;
         this.$alert({ text: `${val?'Non-aktifkan':'Aktifkan'} kategori ini ?`, type: 'warning',
            ok: () => {
               api.edit('transaction',`${dat.transaction_id}/${val?'inactivate':'activate'}`).then(rsl => {
                  if(rsl.error) {
                     this.$alert('Ooops!', rsl.error, 'error');
                  }
                  if(rsl.success) {
                     this.$alert({ title: 'Success', text: rsl.success, type: 'success', timer: 2000 });
                     this.$refs.crud.getData();
                  }
               });
            },
            cancel: () => {}
         });
      },
   }
};
</script>
